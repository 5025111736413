<template>
  <main class="side-bar-layout slideshow-background" style="grid-template-columns: minmax(0, 1fr) min-content 1px;">
    <section class="side-bar-main-content pretty-scroll">
      <div class="slide w-100 p-0 m-0">
        <div class="container d-flex flex-column p-2 px-md-5 pt-sm-3 pt-md-5 pb-5">
          <div class="card border-0 rounded-0 shadow">
            <div class="card-header d-flex flex-column flex-column-reverse flex-md-row align-items-stretch bg-theme rounded-0 border-bottom align-items-md-center p-0">
              <div v-if="election.theme.logo" class="bg-light p-3 align-self-stretch d-flex align-items-center">
                <img :src="election.theme.logo" style="max-height:50px;max-width:100%" />
              </div>
              <div class="d-flex flex-grow-1 align-items-center justify-content-between p-3 flex-wrap gap-3">
                <h3 class="mb-0">{{ election.title[firstAvailableLocale] }}</h3>
                <div class="d-flex align-items-center gap-2">
                  <span>{{ voter.name }}</span>
                  <button @click="destroySession" class="btn btn-sm btn-theme-danger"><i class="fas fa-door-open me-1"></i>
                    {{ $t("js.actions.sign_out") }}
                  </button>
                  <LocaleSwitcher />
                </div>
              </div>
            </div>

            <div class="card-body p-4 p-md-5 d-flex flex-column">
              <h3 class="text-center mb-3">
                {{ $t("js.sign_up.sign_up_for") }} {{ election.title[firstAvailableLocale] }}
              </h3>
              <div class="mb-3" v-html="election.sign_up.info[firstAvailableLocale]"></div>
              <transition name="fade" mode="out-in">
                <div v-if="!answered" key="not_answered">
                  <div class="d-none d-md-flex justify-content-center gap-3">
                    <AsyncButton :on-click="registerAbsence" class="btn w-50 btn-outline-danger" :class="isAbsent ? 'active' : ''">
                      {{ $t("js.sign_up.not_participating") }}<br>
                      <span class="small">{{ $t("js.sign_up.not_participating_small") }}</span>
                      <template #waiting>
                        <i class="fas fa-spin fa-spinner"></i>
                      </template>
                    </AsyncButton>
                    <AsyncButton :on-click="registerParticipation" :class="isParticipating ? 'active' : ''" style="min-width: 100px" class="btn w-50 btn-outline-success">
                      {{ $t("js.sign_up.participating") }}<br>
                      <span class="small">{{ $t("js.sign_up.participating_small") }}</span>
                      <template #waiting>
                        <i class="fas fa-spin fa-spinner"></i>
                      </template>
                    </AsyncButton>
                  </div>
                  <div class="d-md-none">
                    <AsyncButton :on-click="registerAbsence" class="btn w-100 btn-outline-danger mb-3" :class="isAbsent ? 'active' : ''">
                      {{ $t("js.sign_up.not_participating") }}<br>
                      <span class="small">{{ $t("js.sign_up.not_participating_small") }}</span>
                      <template #waiting>
                        <i class="fas fa-spin fa-spinner"></i>
                      </template>
                    </AsyncButton>
                    <AsyncButton :on-click="registerParticipation" :class="isParticipating ? 'active' : ''" class="btn w-100 btn-outline-success">
                      {{ $t("js.sign_up.participating") }}<br>
                      <span class="small">{{ $t("js.sign_up.participating_small") }}</span>
                      <template #waiting>
                        <i class="fas fa-spin fa-spinner"></i>
                      </template>
                    </AsyncButton>
                  </div>
                </div>
                <div v-else class="p-3 text-center border" :class="isParticipating ? 'border-success' : 'border-danger'" key="answered">
                  <p class="lead">{{ $t("js.sign_up.thanks") }}</p>
                  <div v-if="isParticipating" class="lead text-success">
                    {{ $t("js.sign_up.participating") }}
                  </div>
                  <div v-if="isAbsent" class="text-danger lead">
                    {{ $t("js.sign_up.not_participating") }}
                  </div>

                  <div class="mt-3">
                    <button @click="changeAnswer()" class="btn btn-sm btn-outline-dark">{{ $t("js.sign_up.change_answer") }}</button>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import {mapActions, mapState, mapWritableState} from "pinia";
import { defineComponent } from "vue";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { useSignUpSessionStore } from "@/entrypoints/stores/sign_up_session";
import LocaleSwitcher from "@/components/frontend/shared/LocaleSwitcher.vue";
import AsyncButton from "@/components/shared/AsyncButton.vue";

export default defineComponent({
  name: "SignUp",
  components: {
    AsyncButton,
    LocaleSwitcher,
  },
  computed: {
    ...mapState(useSharedStore, ["election", "electionUrl", "firstAvailableLocale"]),
    ...mapState(useSignUpSessionStore, ["voter"]),
    ...mapWritableState(useSignUpSessionStore, ["answered"]),
    availableLocales() {
      return this.$i18n.availableLocales;
    },
    isParticipating() {
      return this.voter.participating;
    },
    isAbsent() {
      return this.voter.participating !== null && !this.voter.participating;
    },
  },
  methods: {
    ...mapActions(useSignUpSessionStore, ["destroySession", "submitAnswer"]),
    async registerParticipation() {
      await this.submitAnswer(true);
    },
    async registerAbsence() {
      await this.submitAnswer(false);
    },
    changeAnswer() {
      this.answered = null;
    },
  }
});
</script>
