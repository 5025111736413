<template>
  <div class="row rounded shadow">
    <div class="col-lg-4 p-3 p-md-4 bg-white" v-if="election">
      <div v-if="organisationBranding.name" class="mb-3 mt-n3 mt-md-n4 mx-n3 mx-md-n4 p-1 bg-secondary text-center">
        <span class="sub-lead"><b>{{ organisationBranding.name }}</b></span>
      </div>
      <LocaleSwitcher class="mb-3"/>
      <img v-if="election.theme.logo" :src="election.theme.logo" class="mb-3" style="max-height:100px;max-width:100%" />
      <div class="lead mb-0">{{ $t("js.sign_up.sign_up_for") }}:</div>
      <div class="h3">{{ election.title[$i18n.locale] }}</div>
    </div>
    <div class="col-lg-8 p-3 p-md-4 bg-theme d-flex flex-column justify-content-center">
      <h4>{{ $t("js.sign_up.sign_in_header") }}</h4>
      <form class="form" @submit.prevent="attemptLogin(electionCodes.map((ec: ElectionCode) => electionCodeValues[ec.id]))" autocomplete="off">
        <div class="mb-3" v-for="(electionCode, index) in electionCodes">
          <label :for="`election_code_${index}`" class="form-label">{{ electionCode.label[$i18n.locale] }}</label>
          <div class="input-group password-input-group">
            <input :id="`election_code_${index}`" :type="showPassword[electionCode.id] ? 'text' : 'password'" class="form-control"
                  :placeholder="electionCode.placeholder[$i18n.locale]"
                  v-model="electionCodeValues[electionCode.id]" autocomplete="off" :autofocus="index===0 || null" value="">
            <div :aria-checked="showPassword[electionCode.id] || null" :aria-label="$t('js.authenticate.show_password')"
                class="btn password-toggle" role="checkbox"
                @click="showPassword[electionCode.id] = !showPassword[electionCode.id]">
              <span class="fas" :class="showPassword[electionCode.id] ? 'fa-eye' : 'fa-eye-slash'"></span>
            </div>
          </div>
          <small>{{ electionCode.help[$i18n.locale] }}</small>
        </div>
        <transition name="fade" mode="out-in">
          <div class="p-3 mb-3 bg-theme-danger" v-if="error">
            <a class="float-end" @click="dismissError()" :aria-label="$t('js.actions.close')">
              <span class="fas fa-times"></span>
            </a>
            {{ errorMessage }}
          </div>
        </transition>

        <div class="mb-3 d-flex justify-content-end">
          <button type="submit" :disabled="!canLogin" class="btn btn-outline-contrast rounded-0">
            <span v-if="waitingForResponse" class="fas fa-spin fa-spinner"></span>
            {{ $t("js.authenticate.sign_in_button") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { useSharedStore } from "@/entrypoints/stores/shared";
import { useSignUpSessionStore } from "@/entrypoints/stores/sign_up_session";
import { useFrontendThemeStore } from "@/entrypoints/stores/frontend_theme";
import {mapState, mapActions, mapWritableState} from "pinia";
import { defineComponent } from "vue";
import LocaleSwitcher from "@/components/frontend/shared/LocaleSwitcher.vue";
import type { ElectionCode } from "@/types";

export default defineComponent({
  name: "Authenticate",
  components: { LocaleSwitcher },
  data() {
    return {
      electionCodeValues: {},
      showPassword: { 1: false, 2: false, 3: false },
      waitingForResponse: false,
    }
  },
  methods: {
    ...mapActions(useSignUpSessionStore, ["authenticate"]),
    async attemptLogin(electionCodes: string[]) {
      //strip leading and trailing whitespace to protect against copy paste errors
      this.waitingForResponse = true;
      await this.authenticate(electionCodes.map(e_code => e_code.trim()));
      this.waitingForResponse = false;
    },
    dismissError() {
      this.error = null;
    },
  },
  computed: {
    ...mapState(useSharedStore, ["election"]),
    ...mapWritableState(useSignUpSessionStore, ["error"]),
    ...mapState(useFrontendThemeStore, ["organisationBranding"]),
    errorMessage() {
      return this.$t(`js.authenticate.errors.${this.error}`);
    },
    electionCodes() {
      return this.election.electionCodeTexts;
    },
    canLogin() {
      return !this.waitingForResponse && this.electionCodes.every((code: ElectionCode) => {
        return this.electionCodeValues[code.id];
      }, this);
    }
  },
  mounted: function () {
    if (window.location.hash) {
      let segments = location.hash.slice(1).split(",");
      location.hash = "";
      let direct = false;
      if (segments[0] === "d") {
        segments = segments.slice(1);
        direct = true;
      }
      const codes = new Map();
      for (let segment of segments) {
        const match = segment.match(/^([123]):([a-zA-Z0-9_]+)$/);
        if (match !== null) {
          const [_, num, inputValue] = match;
          codes.set(num, inputValue);
        }
      }
      this.electionCodeValues = Array.from(codes.entries()).reduce((main, [key, value]) =>
        ({...main, [key]: value}), {});
      if (direct) {
        this.attemptLogin([...codes.values()]);
      }
    }
  },
});
</script>
